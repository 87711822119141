<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<mshipsversiontemplate
				:modelName="modelName"
				:parentObject="this"
				:formDisabled="formDisabled"
				:isCreatingElement="isCreatingElement"
			></mshipsversiontemplate>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mshipsversiontemplateform from './MshipsversionTemplateForm';

export default {
	name: 'mshipsversion-form',
	mixins: [PuiFormMethodsMixin],
	components: { mshipsversiontemplate: mshipsversiontemplateform },
	data() {
		return {
			modelName: 'mshipsversion',
			parentModelName: 'mships',
			positions: [
				{
					text: this.$t('mships.Bow'),
					value: 'Bow'
				},
				{
					text: this.$t('mships.Stern'),
					value: 'Stern'
				}
			]
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	mounted() {
		const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
		if (parentModel != null) {
			const pk = parentModel.pk;
			this.model.shipid = pk.shipid;
			this.model.version = pk.version;
		}
	},
	computed: {},
	created() {}
};
</script>
